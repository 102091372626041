import moment from "moment";
import { IconButton } from "@mui/material";
import { Chat } from "@mui/icons-material";
import Link from 'next/link'


export default [
    {
        field: "contact_id",
        headerName: "Chat",
        description: "Abrir chat",
        width: 50,
        renderCell: (params) => {
            if (!params.value || params.value == "") return (<IconButton disabled>
                <Chat />
            </IconButton>)
            return <Link href={`/contact?id=${params.value}`}>
                <IconButton onClick={() => { console.log(params.row) }}>
                    <Chat />
                </IconButton>
            </Link>
        }
    },
    {
        field: "date",
        headerName: "Fecha Ingreso",
        description: "Fecha Ingreso",
        width: 150,
        renderCell: (params) => {
            return moment(new Date(params.value)).format('L')
        }
    },
    {
        field: "date_sale",
        headerName: "Fecha Venta",
        description: "Fecha Venta",
        width: 150,
        renderCell: (params) => {
            return params.value ? moment(new Date(params.value)).format('L') : ''
        }
    },
    {
        field: "name",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "ticket_id",
        headerName: "ID",
        description: "ID",
        width: 130,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "ticket_sale_id",
        headerName: "ID Venta",
        description: "ID Venta",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },

    {
        field: "status",
        headerName: "Estado",
        description: "Estado",
        width: 150,
        cellClassName: (params) => {
            if (params.row.sold == true && params.row.cancel == false) {
                return 'vendido';
            }
            if (params.row.sold == false && params.row.cancel == true) {
                return 'novendido'
            }
            if (params.row.sold == false && params.row.cancel == false) {
                return 'default'
            }
        }
    },

    {
        field: "user",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 150,
        sortable: false,
        renderCell: (params) => {
            return params.value ? params.value.name + ' ' + params.value.lastname : '';
        }
    },
    {
        field: "phone",
        headerName: "Teléfono",
        description: "Teléfono",
        width: 150,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "prospect",
        headerName: "Atención a contacto",
        description: "Atención a contacto",
        width: 150,
        renderCell: (params) => {
            let result = params.value ? params.value.stats.attention : ''
            result = new Map([
                ['not_actions', 'Sin acciónes'],
                ['', 'Sin acciónes'],
                ['chat', 'Chat'],
                ['call', 'Llamada'],
                ['call_chat', 'Llamada + Chat'],
                ['tracing', 'Seguimiento'],
                ['tracing_chat', 'Seguimiento + Chat'],
            ]).get(result)

            return result;
        }
    },

    {
        field: "sourceProspect",
        headerName: "Origen de contacto",
        description: "Origen de contacto",
        width: 150,
        renderCell: (params) => {

            return params.value;
        }
    },

    // {
    //     field: "type",
    //     headerName: "Fuente",
    //     description: "Fuente",
    //     width: 200,
    //     renderCell: (params) => {
    //         return params.value;
    //     }
    // },
    {
        field: "name",
        headerName: "Nombre",
        description: "Nombre",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "product",
        headerName: "Producto",
        description: "Producto",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "type",
        headerName: "Tipo",
        description: "Tipo",
        width: 150,
        renderCell: (params) => {
            return params.value;
        }
    },
]