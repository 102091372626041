import { getCompany } from '@/actions/auth';
import GraphPanel from '@/components/graph/GraphPanel';
import { useMemo } from 'react';
import { chartsManagement } from '../../constants/chartsTypes';

export default function Management({ user }) {
    const company = useMemo(() => getCompany(), [])
    const graphs = useMemo(() => Object.values(chartsManagement({ company })), [company])

    return (
        <GraphPanel
            title='Gestion'
            useAsUser={user}
            graphs={graphs as any}
        />
    )
}
