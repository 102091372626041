import { getCompany } from '@/actions/auth';
import GraphPanel from '@/components/graph/GraphPanel';
import { useMemo } from 'react';
import { allChartsType } from '../../constants/chartsTypes';

export default function Personalize({ user, dashboardChart }) {
    const { name, charts } = dashboardChart || {}
    const company = useMemo(() => getCompany(), [])
    const graphs = useMemo(() =>
        charts.map(({ chartId }) => allChartsType({ company })[chartId]).filter(Boolean), [charts, dashboardChart])

    return (
        <GraphPanel
            title={name}
            useAsUser={user}
            graphs={graphs}
        />
    )
}
