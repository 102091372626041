import { Avatar } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

export function ItemAgentForColumns({ agent, className }: { agent: any, className?: string }) {
    const { name, lastname, photo } = agent || {}

    return <div className="flex items-center text gap-2">
        {
            !agent
                ? 'Sin agente'
                : <>
                    <Avatar className={cn('bg-primary  font-medium flex items-center justify-center text-[10px] text-white w-[23px] h-[23px]', className)}>
                        {photo ? <img src={photo} className="w-full h-full object-cover" /> : `${name?.[0] || ''}${lastname?.[0] || ''}`}
                    </Avatar>
                    {name} {lastname}
                </>
        }
    </div>
}