import moment from "moment";
import callModel from "./callModel";
import closesModel from "./closesModel";
import prospectModel from "./prospectModel";
import receiptContactModel from "./receiptContactModel";
import receiptModel from "./receiptModel";
import tracingModel from "./tracingModel";
import whatsappNoteModel from "./whatsappNoteModel";
import visitsModel from './visitsModel'
import unofficialWhatsappModel from "./unofficialWhatsappModel";
import chatsModel from "./chatsModel";




export default (module:string) => {

    switch(module){

        case 'calls':
            return callModel
        case 'tracings':
            return tracingModel
        case 'contacts':
            return prospectModel
        case 'closes':
            return closesModel
        case 'closescontact':
            return closesModel
        case 'receipts':
            return receiptModel
        case 'receiptscontact':
        case 'receiptsventiacontact':
            return receiptContactModel
        case 'whatsapp-notes':
            return whatsappNoteModel
        case 'visits':
            return visitsModel
        case 'chats':
            return chatsModel
        case 'chats-wpp':
            return unofficialWhatsappModel
            
        default: 
        return receiptModel
    }

    return 
}