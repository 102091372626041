import { getCompany } from '@/actions/auth'
import GraphPanel from '@/components/graph/GraphPanel'
import moment from 'moment'
import { useMemo } from 'react'
import { chartsManagement, chartsSales } from '../../constants/chartsTypes'

export default function Sales({ user }) {
    const company = useMemo(() => getCompany(), [])

    const graphs = useMemo(() => {
        const chartsList = Object.values(chartsSales({ company }))
        chartsList.splice(6, 0, chartsManagement({ company })['channelSales'])
        
        return chartsList
    }, [company])

    return (
        <GraphPanel 
            title='Ventas'
            useAsUser={user}
            defaultGroupType={"total"}
            initialDateFilter={"last_3_month"}
            graphs={graphs as any}
            filters={[
                {
                    title: "Origen",
                    key: 'origin',
                    keysModel: {
                        receiptsventiacontact: 'sourceProspect'
                    },
                    options: [
                        ...company.origins.map((o) => [o.code, o.name])
                    ]
                },
                {
                    title: 'Atención',
                    key: 'atention',
                    keysModel: {
                        receiptsventiacontact: 'prospect.stats.attention'
                    },
                    options: [
                        ['not_actions', 'Sin acciónes'],
                        ['chat', 'Chat'],
                        ['call', 'Llamada'],
                        ['call_chat', 'Llamada + Chat'],
                        ['tracing', 'Seguimiento'],
                        ['tracing_chat', 'Seguimiento + Chat'],
                    ]
                },
                ...["6411f1c9f7d9b6ecad552808"].includes(company._id) ? [
                    {
                        title: 'Tipo',
                        key: 'type',
                        keysModel: {
                            receiptsventiacontact: 'type'
                        },
                        options: [
                            ["NUEVA", "NUEVA"],
                            ["Renovaciones", "Renovaciones"],
                        ]
                    }
                ] : []
            ]}
            initialDateValue={
                {
                    gt: moment().add(-2, 'month').startOf('month').startOf('day').add(-1, 'd').toISOString(),
                }
            }
        />
    )
}
