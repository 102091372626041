import { Box, IconButton } from "@mui/material";
import moment from "moment";
import { getCompany, isAuth } from "../../../actions/auth";
import Link from "next/link";
import { Chat } from "@mui/icons-material";


export default [
    {
        field: "contactid",
        headerName: "Contacto",
        description: "Contacto",
        width: 100,
        renderCell: (params) => {
            return params.value ?
                <><Link href={`/contact?id=${params.value}`}><IconButton ><Chat fontSize='small' sx={{ color: "#aaa" }} /></IconButton></Link></>
                :
                ''
        }
    },
    {
        field: "createdAt",
        headerName: "Fecha de cierre",
        description: "Fecha de cierre",
        width: 200,
        renderCell: (params) => {
            return moment(new Date((params.value))).format('L LTS')

        }
    },
    {
        field: "agent_email",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 150,
        renderCell: (params) => {
            return params.value ? `${params.value.name} ${params.value.lastname}` : ''
        }
    },
    {
        field: "from",
        headerName: "Cierre anterior",
        description: "Cierre anterior",
        width: 150,
        renderCell: (params) => {
            if (params.value == "archivedUntilSchedule") return "Recordatorio"
            if (params.value == "waitingForReply") return "Esperando respuesta"
            if (params.value == "inactive") return "Inactivo"
            if (params.value == "notInterested") return "No interesado"
            if (params.value == "converted") return "Convertido"
            if (params.value == "withoutContactInformation") return "Sin información de contacto"
            return params.value
        }
    },
    {
        field: "to",
        headerName: "Cierre",
        description: "Cierre",
        width: 150,
        renderCell: (params) => {

            if (params.value == "archivedUntilSchedule") return "Recordatorio"
            if (params.value == "waitingForReply") return "Esperando respuesta"
            if (params.value == "inactive") return "Inactivo"
            if (params.value == "notInterested") return "No interesado"
            if (params.value == "converted") return "Convertido"
            if (params.value == "withoutContactInformation") return "Sin información de contacto"
            return params.value
        }
    },
    // {
    //     field: "jsonme",
    //     headerName: "Vendedor",
    //     description: "vendedor asignado",
    //     width: 5000,
    //     renderCell: (params) => {

    //         // if(params.value.agent)return params.value.agent.firstName+" "+params.value.agent.lastName
    //         return JSON.stringify(params.row)
    //     }
    // },
    // {
    //     field: "nombreContacto",
    //     headerName: "Nombre del cliente",
    //     description: "Nombre del cliente",
    //     width: 200,
    //     renderCell: (params) => {
    //         return params.row.prospectid.firstName
    //     }
    // },
    // {
    //     field: "apellidoContacto",
    //     headerName: "Apellido del cliente",
    //     description: "apellido del cliente",
    //     width: 200,
    //     renderCell: (params) => {
    //         return params.row.prospectid.lastName
    //     }
    // },
    // {
    //     field: "phones",
    //     headerName: "Teléfono",
    //     description: "teléfono contacto",
    //     width: 150,
    //     renderCell: (params) => {
    //         return params.row.prospectid.phones[0]
    //     }
    // },
    // {
    //     field: "archivingReason",
    //     headerName: "Motivo de cierre",
    //     description: "Motivo de cierre",
    //     width: 200,
    //     renderCell: (params) => {
    //         if(params.row.prospectid.archivingReason == "archivedUntilSchedule") return "Recordatorio"
    //         if(params.row.prospectid.archivingReason == "waitingForReply") return "Esperando respuesta"
    //         if(params.row.prospectid.archivingReason == "inactive") return "Inactivo"
    //         if(params.row.prospectid.archivingReason == "notInterested") return "No interesado"
    //         if(params.row.prospectid.archivingReason == "converted") return "Convertido"
    //         if(params.row.prospectid.archivingReason == "withoutContactInformation") return "Sin información de contacto"
    //         return params.row.prospectid.archivingReason
    //     }
    // },
    // {
    //     field: "estado",
    //     headerName: "Estado",
    //     description: "estado del archivado",
    //     width: 200,
    //     renderCell: (params) => {
    //         if(params.row.prospectid.status == "new") return "Nuevo"
    //         if(params.row.prospectid.status == "archived") return "Archivado"
    //         if(params.row.prospectid.status == "followUp") return "En seguimiento"

    //         if(getCompany() && getCompany().origin){
    //             let origin = getCompany().origin.filters((o) => o.code == params.row.prospectid.status )
    //             if(origin.length > 0) return origin[0].name
    //         }
    //         return params.row.prospectid.status
    //     }
    // }, 
    // {
    //     field: "origen",
    //     headerName: "Origen",
    //     description: "origen del prospecto",
    //     width: 200,
    //     renderCell: (params) => {
    //         return params.row.prospectid.leads[0].source
    //     }
    // },
]