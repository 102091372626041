import { Chat } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import Link from "next/link";
import { getCompany } from "../../../actions/auth";


export default [

    {
        field: "contact",
        headerName: "Contacto",
        description: "Contacto",
        width: 100,
        renderCell: (params) => {
            return params.value ? 
            <><Link href={`/contact?id=${params.value}`}><IconButton ><Chat fontSize='small' sx={{color: "#aaa"}}/></IconButton></Link></>
            : 
            ''
        }
    },
    {
        field: 'createdAt',
        headerName: 'Fecha creación',
        description: 'Fecha creación',
        width: 150,
        renderCell: (params) => {
            return moment(params.value).format('L LTS')
        } 
    },
    {
        field: 'content.origin',
        headerName: 'Enviado por',
        description: 'Enviado por',
        width: 150,
        renderCell: (params) => {
            return _.get(params.row, 'content.origin', '') == 'agent' ? 'Vendedor' : 'Cliente'
        }
    },



    {
        field: 'content.message',
        headerName: 'Contenido',
        description: 'Contenido',
        width: 300,
        renderCell: (params) => {
            let message = _.get(params, 'row.content.message', '')
            let type = _.get(params, 'row.content.mediaType', '')
            if(message == ''){
                if(type == 'image'){
                    return 'Imagen'
                }else if(type == 'video'){
                    return 'Video'
                }else if(type){
                    return type
                } 
            }
            return _.get(params, 'row.content.message', '')
        }
    },

    {
        field: 'agent',
        headerName: 'Vendedor',
        description: 'Vendedor',
        width: 150,
        sortable: false,
        renderCell: (params) => {
            return params.value ? _.get(params.value, 'name') + ' ' + _.get(params.value, 'lastname') : ''
        } 
    },
]