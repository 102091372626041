import React, { useState, useEffect, ReactElement } from "react";
import {
    Button,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, esES, GridColumns } from "@mui/x-data-grid";
import { Box, Grid, Modal } from "@mui/material";
import {
    ArrowForwardIos,
    Close,
    FilterAlt,
    InfoOutlined,
} from "@mui/icons-material";
import { weekgoalDetail } from "../../actions/comercial/metas";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import FilterMultiSelect from "../reports/FilterMultiSelect";
import Link from "next/link";
import { useQuery } from "@tanstack/react-query";
import { getGraphData } from "../../actions/graph";
import { GraphInterface } from "./Graph";
import { darken, lighten } from "@mui/material/styles";
import { getActiveUsersFilter } from "../../actions/group";

interface GraphDetailInterface {
    open: boolean;
    close: () => void;
    config?: GraphInterface;
    series?: string;
    category?: string;
    categoryText?: string;
    seriesText?: string;
    model: GridColumns;
    id_field: string;
}

const replaceUsersIDS = (
    type: string,
    users: any[],
    item: { [key: string]: any }
) => {
    switch (type) {
        case "receipts":
        case "receiptscontact":
        case 'receiptsventiacontact':
        case "tracings":
        case "calls":
            return replaceUsers(item, users, ["user"]);
            break;
        case "whatsapp-notes":
        case "chats-wpp":
        case "chats":
        case "visits":
            return replaceUsers(item, users, ["agent"]);
        case "closes":
        case "closescontact":
            return replaceUsers(item, users, ["agent_email"], "email");
            break;
    }
    return item;
};

const replaceUsers = (
    item: { [key: string]: any },
    users: any[],
    keys: string[],
    matchKey: string = "_id"
) => {
    keys.map((key) => {
        if (item[key] && typeof item[key] == "string") {
            let users_candidates = users.filter(
                (u) => u[matchKey] == item[key]
            );
            if (users_candidates.length > 0) {
                item[key] = users_candidates[0];
            } else {
                item[key] = {
                    _id: item[key],
                    name: "Usuario",
                    lastname: "no encontrado",
                };
            }
        }
    });
    return item;
};

export const GraphDetail = ({
    open,
    close,
    config,
    series,
    seriesText,
    category,
    categoryText,
    model,
    id_field,
}: GraphDetailInterface) => {
    const reload = async () => {
        let useSeries = series;
        if (
            config.nulltext
                ? useSeries == config.nulltext
                : useSeries == "Sin definir"
        ) {
            useSeries = null;
        }
        let result = await getGraphData(
            { ...config, detail: { series: useSeries, category } },
            "receipt"
        );

        return result;
    };

    const query = useQuery({
        queryFn: reload,
        queryKey: config
            ? [
                "specific_graph",
                series,
                category,
                config.source,
                config.colorby,
                config.colors,
                config.datefilter,
                config.group,
                config.groupby,
                config.labels,
                config.percentage,
                config.sumtype,
                config.title,
                config.updateVersion,
                config.userfilter,
                config.match,
            ]
            : ["specific_graph"],
        enabled: open,
    });

    const queryUsers = useQuery({
        queryFn: getActiveUsersFilter,
        queryKey: ["users-list-filter"]
    });

    let list = [];
    if (query.data) {
        query.data.list.map((ele) => {
            list = [...list, ...ele.object_list];
        });
        if (queryUsers.data) {
            // list = [...list].map((item) => ({...item, user: {name: 'pepe'}}))
            list = list.map((item) => {
                return replaceUsersIDS(
                    config.source,
                    queryUsers.data.users,
                    item
                );
            });
        }
    }

    const getBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.2) : lighten(color, 0.2);

    const getHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.6) : lighten(color, 0.1);

    return (
        <Modal open={open} onClose={close}>
            <Box
                sx={{
                    "& .header-meta, .MuiDataGrid-columnsContainer": {
                        backgroundColor: "#0080ff",
                        color: "#fff",
                    },
                    "& .header-meta .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: 600,
                    },
                    "& .MuiDataGrid-columnHeaderWrapper ": {
                        height: 20,
                    },

                    "& .default": {
                        color: (theme) =>
                            getBackgroundColor(
                                theme.palette.info.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            color: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.info.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    "& .vendido": {
                        color: (theme) =>
                            getBackgroundColor(
                                theme.palette.success.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            color: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.success.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    "& .novendido": {
                        color: (theme) =>
                            getBackgroundColor(
                                theme.palette.error.main,
                                theme.palette.mode
                            ),
                        "&:hover": {
                            color: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.error.main,
                                    theme.palette.mode
                                ),
                        },
                    },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "100vh",
                    maxHeight: "700px",
                    maxWidth: "1150px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "15px",
                    p: 3,
                }}
            >
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item mb={2}>
                        <Typography fontSize={24}>
                            {config ? config.title : "Datos sobre grafica"}{" "}
                            <Typography
                                fontSize={18}
                                component={"span"}
                                color={"gray"}
                            >
                                {seriesText ? seriesText : series ? series : ""}
                            </Typography>{" "}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        mb={2}
                        sx={{
                            "&:hover": {
                                color: "#3d9ffb",
                            },
                        }}
                    >
                        <Close sx={{ fontSize: "28px" }} onClick={close} />
                    </Grid>
                </Grid>
                {config.group == "total" ? (
                    <Grid container>
                        <Grid item mr={2}>
                            <Typography fontSize={16} fontWeight="600">
                                Periodo selecciónado
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item mr={2}>
                            <Typography fontSize={16}>
                                {config.group == "user" ? (
                                    <b>Vendedor:</b>
                                ) : (
                                    <b>Fecha:</b>
                                )}{" "}
                                {categoryText}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={list.map((item) => ({ ...item, id: item[id_field] }))}
                    columns={model}
                    loading={query.isFetching}
                    disableColumnMenu={true}
                    rowsPerPageOptions={[100]}
                    density="compact"
                />
            </Box>
        </Modal>
    );
};

GraphDetail.defaultProps = {
    id_field: "_id",
};

export default GraphDetail;
